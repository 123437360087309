import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import classnames from "classnames";

import Slider from "components/Slider";
import Figure from "common/Figure";
import PictureCaption from "components/PictureCaption";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemPanel } from "components/Accordion";
import LocalizedLink from "components/LocalizedLink";
import SEO from "components/Seo";
import paths from "config/paths";
import AllOn4JsonLd from "components/AllOn4JsonLd";

import * as styles from "styles/all-on-4.module.scss";
import * as pageStyles from "styles/page-styles.module.scss";
import ContactForm from "../components/ContactForm";
import Button from "../common/Button";

const AllOnFourPage = () => {
  const data = useStaticQuery(query);

  return (
    <>
      <AllOn4JsonLd />
      <>
        <SEO
          title="All-on-4&trade;"
          description="Odzyskaj uśmiech w jeden dzień dzięki All-on-4™. Do 12 zębów na 4 implantach. Najlepsza metoda na bezzębie. Razem z Paulo Malo i Malo Dental - zabieg w Szczecinie lub Gorzowie Wielkopolskim."
        />
        <div className={pageStyles.heroSection}>
          <div className={pageStyles.hero}>
            <div className="mb-4">
              <h1>
                Implanty <span className="nowrap">All-on-4&trade;</span>
              </h1>
              <h2>Kompleksowe rozwiązanie problemu bezzębia w jeden dzień.</h2>
              <Button to="#contact-form" data-track-click data-track-category="leadgen">Umów wizytę</Button>
            </div>
            <Figure>
              <Img fluid={data.hero.childImageSharp.fluid} className={pageStyles.image} />
            </Figure>
          </div>
        </div>
        <article>
          <div className={pageStyles.bgSectionPrimary}>
            <div className={pageStyles.headerTextSection}>
              <p>
                Utrata wszystkich zębów wiąże się z bardzo dużymi trudnościami w codziennym funkcjonowaniu. Powszechnie
                brakujące zęby, są zastępowane protezami, które ruszają się w trakcie jedzenia, mówienia i uśmiechania
                się. Pojawiają się kompleksy, unikanie towarzystwa i znaczne ograniczenia.
              </p>
              <p>
                Aby zminimalizować niekorzystne efekty utraty wszystkich zębów można wykorzystać tylko 4 implanty,
                dzięki którym jesteśmy w stanie zęby umocować na stałe, jednocześnie eliminując wszystkie niedogodności.
              </p>
            </div>
          </div>
          <section className={pageStyles.baseSection}>
            <div className={pageStyles.headerSideSection}>
              <h2>
                Metoda&nbsp;<span className="nowrap">All-on-4&trade;</span>{" "}
              </h2>
              <div>
                <div className={styles.item}>
                  <h3 className={styles.itemTitle}>Zęby w jeden dzień</h3>
                  <p>
                    Pacjent otrzymuje stałą protezę wspartą na czterech implantach w dniu zabiegu chirurgicznego. To
                    szybko skutkuje zwiększoną satysfakcją - w zakresie funkcji, estetyki, odczuwania, mowy i poczucia
                    własnej wartości.
                  </p>
                </div>
                <div className={styles.item}>
                  <h3 className={styles.itemTitle}>Najlepsze rozwiązanie w przypadku bezzębia</h3>
                  <p>
                    Jednorazowy zabieg, bez konieczności wykonywania dodatkowych procedur i regeneracji kości. Uśmiech
                    zostaje odtworzony w jeden dzień.
                  </p>
                </div>
                <div className={styles.item}>
                  <h3 className={styles.itemTitle}>Sprawdza się w trudnych warunkach kostnych</h3>
                  <p>
                    90% pacjentów, których leczenie zostało uznane za niemożliwe tradycyjnymi metodami
                    implantologicznymi, można wyleczyć za pomocą koncepcji&nbsp;
                    <span className="nowrap">All-on-4&trade;</span>.
                  </p>
                </div>
                <div className={styles.item}>
                  <h3 className={styles.itemTitle}>Zęby są odtworzone na stałe, bez wyciągania</h3>
                  <p>Proteza umocowana jest na stałe do implantów, zachowuje się i wygląda jak naturalne uzębienie.</p>
                </div>
              </div>
            </div>
          </section>
          <div className={pageStyles.divider} />
          <section className={pageStyles.baseSection}>
            <div className={pageStyles.headerSideSection}>
              <h2>
                Zabieg implantologiczny <span className="nowrap">All-on-4&trade;</span>
              </h2>
              <div>
                <p>
                  <span className="nowrap">All-on-4&trade;</span> to procedura implantologiczna, która pozwala w ciągu
                  jednego dnia na umieszczenie 4 implantów i odtworzenie na stałe umocowanych zębów, których nie trzeba
                  wyciągać.
                </p>
                <p>
                  Dzięki metodzie <span className="nowrap">All-on-4&trade;</span> już po kilku godzinach pacjenci mogą
                  się cieszyć nowymi zębami, które nie ruszają sie w czasie jedzenia, nie zajmują podniebienia i
                  pozwalają odczuwać smaki spożywanych posiłków. Mówienie nie sprawia problemów, a przede wszystkim zęby
                  wyglądają na naturalne i zdrowe.
                </p>
                <p>
                  Dzięki szybkiemu leczeniu oraz wyeliminowaniu długotrwałych zabiegów odbudowywania kości, pacjenci
                  unikają długotrwałego użytkowania protez, wzrasta ich pewność siebie i zwiększa się samoocena.
                </p>
              </div>
            </div>
          </section>
          <div className={pageStyles.baseSection}>
            <section className={pageStyles.appContainer}>
              <Slider>
                <div className={styles.slide}>
                  <Img
                    fluid={data.allon4Image2.childImageSharp.fluid}
                    imgStyle={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                    alt={`Dr Paulo Malo wykonuje zabieg All-on-4 w klinice stomatologicznej Aesthetic Dent w Szczecinie.`}
                    className={pageStyles.image}
                  />
                </div>
                <div className={styles.slide}>
                  <Img
                    fluid={data.allon4Image3.childImageSharp.fluid}
                    imgStyle={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                    className={pageStyles.image}
                  />
                </div>
                <div className={styles.slide}>
                  <Img
                    fluid={data.allon4Image4.childImageSharp.fluid}
                    imgStyle={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                    alt={`Proces powstawania protezy na 4 implantach. Laboratorium protetyczne w klinice stomatologicznej Aesthetic Dent w Szczecinie.`}
                    className={pageStyles.image}
                  />
                </div>
                <div className={styles.slide}>
                  <Img
                    fluid={data.allon4Image5.childImageSharp.fluid}
                    imgStyle={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                    alt={`Dr Marcin Tutak i dr Tomasz Smektała podczas zabiegu All-on-4 w klinice stomatologicznej Aesthetic Dent w Szczecinie.`}
                    className={pageStyles.image}
                  />
                </div>
                <div className={styles.slide}>
                  <Img
                    fluid={data.allon4Image6.childImageSharp.fluid}
                    imgStyle={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                    className={pageStyles.image}
                  />
                </div>
              </Slider>
            </section>
          </div>
          <section className={pageStyles.baseSection}>
            <div className={pageStyles.headerSideSection}>
              <h2>Zalety zabiegu wszczepienia 4 implantów</h2>
              <div>
                <p>
                  Główną zaletą zabiegu <span className="nowrap">All-on-4&trade;</span> jest znaczne skrócenie czasu
                  leczenia, złożoności i liczby zabiegów chirurgicznych. Zwiększone jest bezpieczeństwo metody, co
                  umożliwia jej wykonanie u większej liczby pacjentów, dla których skomplikowane zabiegi chirurgiczne
                  nie są wskazane.
                </p>
                <p>
                  Dodatkowo, umieszczenie tylko 4 implantów w całej szczęce (lub żuchwie), ograniczenie dodatkowych
                  zabiegów chirurgicznych, uproszczone procedury protetyczne umożliwiają znaczne zmniejszenie kosztów
                  leczenia.
                </p>
              </div>
            </div>
          </section>
          <div className={pageStyles.borders}>
            <section className={pageStyles.baseSection}>
              <div className={pageStyles.doubleColSection}>
                <div>
                  <h2>
                    Aesthetic Dent i dr Paulo Malo <span className="nowrap">All-on-4&trade;</span> w Szczecinie i
                    Gorzowie Wielkopolskim
                  </h2>
                  <p>
                    Nawiązaliśmy ścisłą współpracę w zakresie implantologii z{" "}
                    <LocalizedLink to={paths.pmalo}>dr Paulo Malo</LocalizedLink>, jednym z najwybitniejszych i
                    najbardziej pomysłowych lekarzy w leczeniu trudnych przypadków bezzębnej szczęki i żuchwy. Aesthetic
                    Dent jest jedyną oficjalną partnerską kliniką stomatologiczną w Szczecinie i Gorzowie Wielkopolskim
                    wykonującą zabiegi <span className="nowrap">All-on-4&trade;.</span> Zapraszamy na konsultację i
                    kwalifikację do zabiegu w naszych gabinetach w Szczecinie lub w Gorzowie Wielkopolskim.
                  </p>
                </div>
                <Figure>
                  <Img
                    fluid={data.malo.childImageSharp.fluid}
                    alt="Dr Paulo Malo w gabinecie stomatologicznym Aesthetic Dent w Szczecinie."
                    className={pageStyles.image}
                    imgStyle={{ objectFit: "cover", objectPosition: "center top" }}
                  />
                  <PictureCaption caption="Dr Paulo Malo w gabinecie stomatologicznym Aesthetic Dent w Szczecinie." />
                </Figure>
              </div>
            </section>
          </div>
          <section className={pageStyles.baseSection}>
            <div className={pageStyles.headerSideSection}>
              <h2 className={pageStyles.title}>Często zadawane pytania</h2>
              <div className={classnames(pageStyles.text, pageStyles.faq)}>
                <Accordion>
                  <AccordionItem>
                    <AccordionItemHeading ariaLevel={2}>
                      <h2>
                        Dlaczego warto zdecydować się na zabieg&nbsp;
                        <span className="nowrap">All-on-4&trade;</span>?
                      </h2>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Jest to bardzo skuteczna metoda leczenia bezzębia. Zaletą jest znaczne skrócenie czasu leczenia,
                        złożoności i liczby zabiegów chirurgicznych w stosunku do tradycyjnej odbudowy na implantach.
                        Zwiększa się bezpieczeństwo metody co umożliwia jej wykonanie u większej liczby pacjentów, dla
                        których skomplikowane zabiegi chirurgiczne nie są wskazane.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading ariaLevel={2}>
                      <h2>
                        Jakie są wskazania do zabiegu <span className="nowrap">All-on-4&trade;</span>?
                      </h2>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Zabieg <span className="nowrap">All-on-4&trade;</span> wykonuje się u pacjentów bez zębów
                        zarówno w szczęce jak i w żuchwie lub w tych sytuacjach, w których uzębienie wymaga usunięcia.
                        Wiek pacjentów także nie stanowi ograniczeń.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading ariaLevel={2}>
                      <h2>Co to są implanty zygomatyczne?</h2>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Implanty zygomatyczne wykorzystuje się w skrajnie trudnych warunkach anatomicznych. Są to długie
                        implanty, które zakotwicza się w kościach tak by można wykonać na nich na stałe przymocowane
                        sztuczne zęby. Ich umieszczenie jest możliwe tylko i wyłącznie przez doświadczony zespół
                        chirurgów szczękowych. Implanty zygomatyczne również wykorzystuje się w metodzie{" "}
                        <span className="nowrap">All-on-4&trade;</span>.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading ariaLevel={2}>
                      <h2>Ile czasu zajmie mi powrót do pełnej formy?</h2>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Zabieg wprowadzenia implantów jest zabiegiem chirurgicznym, w związku z tym możliwe jest
                        pojawienie się obrzęków i siniaków, które mijają po kilku dniach. Można uznać, że w krótkim
                        czasie pacjenci po zabiegu wszczepienia 4 implantów wracają do pełnej sprawności. Przez pierwsze
                        miesiące po zabiegu wskazane jest jednak spożywanie miękkich pokarmów.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading ariaLevel={2}>
                      <h2>Jak zmieni się moje życie po zabiegu?</h2>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Zastosowanie koncepcji <span className="nowrap">All-on-4&trade;</span> jest doskonałym
                        rozwiązaniem poprawiającym wręcz natychmiastowo komfort życia, ze względu na fakt, że w dniu
                        zabiegu pacjent otrzymuje nowe zęby, umocowane na stałe, których nie musi wyciągać z jamy
                        ustnej. W ciągu kilku godzin poprawia się samoakceptacja, pewność siebie, uśmiech. Zapadnięte
                        policzki i wargi ulegają wypełnieniu dzięki czemu twarz zyskuje młodsze rysy.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading ariaLevel={2}>
                      <h2>
                        Ile kosztuje zabieg&nbsp;
                        <span className="nowrap">All-on-4&trade;</span>?
                      </h2>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Cena za wykonanie <span className="nowrap">All-on-4&trade;</span> jest uzależniona od stopnia
                        trudności zabiegu. Cena pierwszej części zabiegu z reguły wynosi 30.000 zł, w czasie której
                        wprowadza się chirurgicznie implanty oraz wykonuje się zęby tymczasowe na stałe umocowane do
                        implantów. Cena drugiej części wynosi 10.000 - 15.000 zł w zależności od rodzaju wykonania
                        uzupełnienia protetycznego (akryl, ceramika, cyrkon).
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading ariaLevel={2}>
                      <h2>Jak dbać o higienę po zabiegu implantologicznym?</h2>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Utrzymanie higieny jest bardzo ważne wokół implantów. Czystość w tej okolicy zwiększa trwałość
                        wykonanego uzupełnienia protetycznego. Bardzo ważne oprócz rutynowego szczotkowania zębów jest
                        stosowanie dodatkowych urządzeń takich jak nici dentystyczne, irygatory do płukania jamy ustnej.
                        Bardzo duże znaczenie mają także kontrole i profesjonalne zabiegi higieniczne wykonywane w
                        gabinecie stomatologicznym.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading ariaLevel={2}>
                      <h2>Jakie jest ryzyko zabiegowe?</h2>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Zabiegi chirurgiczne zawsze wiążą się z określonym standardowym ryzykiem, takim jak omdlenia,
                        krwawienia, obrzęki, krwiaki, przejściowe uszkodzenia nerwów czuciowych. W przypadku zbyt
                        miękkiej kości będzie możliwe wprowadzenie implantów ale może nie być możliwe wykonanie
                        tymczasowego uzupełnienia protetycznego w dniu zabiegu.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading ariaLevel={2}>
                      <h2>Jak wygląda proteza po zabiegu?</h2>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Wykonane zęby po zabiegu przypominają naturalne, możliwy jest także wybór koloru nowych zębów. Z
                        reguły protezy tymczasowe mają około 10 zębów. Zawsze powinna zostać przestrzeń pomiędzy
                        dziąsłem a wstawionymi zębami aby można było czyścić okolice implantów.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </section>
          <section className="main max">
            <div className="px-m">
              <ContactForm preselectedSubject={"all-on-4"} />
            </div>
          </section>
        </article>
      </>
    </>
  );
};

const query = graphql`
  query {
    hero: file(relativePath: { eq: "allon4-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    allon4Image2: file(relativePath: { eq: "allon4-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    allon4Image3: file(relativePath: { eq: "allon4-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    allon4Image4: file(relativePath: { eq: "allon4-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    allon4Image5: file(relativePath: { eq: "allon4-5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    allon4Image6: file(relativePath: { eq: "allon4-6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    malo: file(relativePath: { eq: "malo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

export default AllOnFourPage;
